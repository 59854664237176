class CurrencyUtils {
    #malawiKwachaCurrencySymbol = "MWK";
    formatAmountInMalawiKwacha(amount) {
        return `${this.#malawiKwachaCurrencySymbol} ${amount.toLocaleString()}`
    }

    isValidNumber(input) {
        if (input === null || input === '') {
            return false;
        }
        const number = Number(input);
        return !isNaN(number) && isFinite(number);
    }

    isMoreThanZero(inputAmount) {
        try {
            if (!this.isValidNumber(inputAmount)) return false;
            return Number(inputAmount) > 0;
        } catch (error) {
            console.error(error)
            return false
        }
    }
}

export default CurrencyUtils;