export const formatDate = (inputDate) => {
    try {
        const convertToYYYYMMDD = dateString => new Date(dateString.split("-").reverse().join("-"))
            .toISOString().split('T')[0];
        return convertToYYYYMMDD(inputDate);
    } catch (err) {
        console.warn(err);
        return inputDate;
    }
}
export const dateStringToYYYYMMDDFormat = (dateString) => {
    return new Date(dateString).toISOString().split('T')[0]
}

export const formatToHumanReadableDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}