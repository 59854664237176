import {Header} from "../components/Header";
import {
    Box,
    Card, CardContent,
    CardHeader,
    Container,
    Divider,
    ListItem,
    Typography
} from "@mui/material";
import {nbsColors} from "../constants/colors";
import * as React from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import loanProducts from "../constants/loanProducts";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import loanRequirements from "../constants/loanRequirements";
import {useNavigate} from "react-router-dom";
import CustomButton from "../components/CustomButton";
import {useEffect, useState} from "react";
import CommonsAPI from "../api/CommonsAPI";
import {Overlay} from "../components/Overlay";
import BackGroundPaper from "../components/BackGroundPaper";
import webRoutes from "../constants/routes";

const isConnectionOk = async () => await new CommonsAPI().isBackendServerReachable();
const isTokenValid = async () => await new CommonsAPI().isTokenValid();


const HomePage = () => {
    const [connected, setConnected] = useState(false);
    const [checkingConnection, setCheckingConnection] = useState(true);

    const navigate = useNavigate();
    const goNextPage = () => navigate(webRoutes.customer);

    useEffect(() => {
        isConnectionOk()
            .then((ok) => {
                setConnected(ok);
                if (!ok) {
                    navigate(webRoutes.maintenance)
                } else {
                    isTokenValid()
                        .then((valid) => {
                            if (valid) {
                                isConnectionOk()
                                    .then((ok) => {
                                        setConnected(ok);
                                        if (!ok) {
                                            navigate(webRoutes.maintenance)
                                        }
                                    })
                                    .catch(() => false)
                                    .finally(() => setCheckingConnection(false))
                            } else {
                                console.error("Token is not valid")
                                navigate(webRoutes.unauthorized)
                            }
                        })
                        .catch(() => false)
                        .finally(() => setCheckingConnection(false))
                }
            })
            .catch(() => false)
            .finally(() => setCheckingConnection(false))

        const disableBack = (e) => {
            e.preventDefault();
            window.history.forward();
        };

        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', disableBack);

        return () => {
            window.removeEventListener('popstate', disableBack);
        }

    }, [])

    return (
        <BackGroundPaper>
           <>
               <Header/>
               <Overlay open={checkingConnection}/>
               <Container maxWidth="md" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                   <Box sx={{ borderRadius: "100px", marginTop: "1rem"}}>
                       <Card elevation={0} sx={{ borderRadius: "10px" }}>
                           <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '1rem' }}>
                               <CardHeader sx={{ color: nbsColors.blue, mb: "-2rem" }}  title="NBS Bank Kachangu loans." />
                           </div>

                           <CardContent>
                               <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                   <Box mt={2} pl={2}>
                                       <Typography style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: nbsColors.blue }} variant="h6">
                                           Kachangu loan products
                                       </Typography>

                                   </Box>
                                   {loanProducts.map((loanProduct,index) => {
                                       return <>
                                           <ListItem alignItems="flex-start">
                                               <ListItemText
                                                   primary={
                                                       <React.Fragment>
                                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ArrowRightIcon style={{ marginRight: '8px' }} />
                                                        <Typography
                                                            variant="body1"
                                                            color={nbsColors.blue}
                                                            fontWeight={600}
                                                        >
                                                            {loanProduct.name}
                                                        </Typography>
                                                    </span>
                                                       </React.Fragment>
                                                   }
                                                   secondary={
                                                       <React.Fragment>
                                                           <Typography
                                                               sx={{ display: 'inline' }}
                                                               component="span"
                                                               variant="body2"
                                                               color={nbsColors.black}
                                                           >
                                                               {loanProduct.description}
                                                           </Typography>
                                                       </React.Fragment>
                                                   }
                                                   primaryTypographyProps={{
                                                       color: nbsColors.blue,
                                                       fontWeight: 600,
                                                   }}
                                               />
                                           </ListItem>
                                       </>
                                   })}
                               </List>

                               <Box pl={2} pr={2} mb={2}>
                                   <Divider variant="fullWidth" />
                               </Box>

                               <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                   <Box pl={2}>
                                       <Typography style={{ display: 'flex', alignItems: 'center', fontWeight: 600, color: nbsColors.blue }} variant="h6">
                                           Requirements
                                       </Typography>

                                   </Box>
                                   {loanRequirements.map((loanRequirement,index) => {
                                       return <>
                                           <ListItem alignItems="flex-start">
                                               <ListItemText
                                                   primary={loanRequirement.title}
                                                   secondary={
                                                       <React.Fragment>
                                                           <Typography
                                                               sx={{ display: 'inline' }}
                                                               component="span"
                                                               variant="body2"
                                                               color={nbsColors.black}
                                                           >
                                                               {loanRequirement.description}
                                                           </Typography>
                                                       </React.Fragment>
                                                   }
                                                   primaryTypographyProps={{
                                                       color: nbsColors.blue,
                                                       fontWeight: 600,
                                                   }}
                                               />
                                           </ListItem>
                                       </>
                                   })}
                               </List>

                               <Box display="flex" justifyContent="center">
                                   <CustomButton label="Continue" onClickCallback={goNextPage}/>
                               </Box>
                           </CardContent>
                       </Card>
                   </Box>
               </Container>
           </>
        </BackGroundPaper>
    );
}
export default HomePage;