const loanRequirements = [
    {
        "title": "Valid National ID",
        "description": "Make sure you have a valid Malawian national ID."
    },
    {
        "title": "NBS Bank Salary Account",
        "description": "Make sure to have an account that is atleast about one month old and is a salary account"
    },
    {
        "title": "Clean CRB Report",
        "description": "Make sure you have never defaulted a loan with NBS or any institution in Malawi"
    }
]

export default loanRequirements;