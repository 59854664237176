import React from "react";
import {Button, Typography} from "@mui/material";
import {nbsColors} from "../constants/colors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useNavigate} from "react-router-dom";

const BackButton = React.memo(() => {
    const navigate = useNavigate();
    return (<Button
        onClick={() => navigate(-1)}
        sx={{
            pl: 1,
            pr: 2,
            textTransform: "none",
            borderRadius: '10px',
            backgroundColor: nbsColors.lightGrey,
            color: nbsColors.blue,
            ":hover": {
                backgroundColor: nbsColors.lightGrey,
                color: nbsColors.blue,
            }
        }}
        startIcon={<ArrowBackIosNewIcon/>}>
        <Typography fontWeight={600}>Back</Typography>
    </Button>);
});

export default BackButton;