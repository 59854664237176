import React from "react";
import {Button, Typography} from "@mui/material";
import {nbsColors} from "../constants/colors";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from "@mui/material/CircularProgress";

const NextButton = React.memo(({ onNextCallback, loaderEffect = false, disabled = false, label='Next'}) => {
    return (<Button
        disabled={disabled}
        onClick={onNextCallback}
        sx={{
            pl: 2,
            pr: 1,
            textTransform: "none",
            borderRadius: '10px',
            backgroundColor: disabled ? nbsColors.lightGrey : nbsColors.red,
            color: disabled ? nbsColors.black : nbsColors.white,
            ":hover": {
                backgroundColor: nbsColors.red,
                color: nbsColors.white,
            }
        }}
        endIcon={<ArrowForwardIosIcon/>}>
        {loaderEffect
            ? (<CircularProgress size="30px" thickness={2} sx={{ color: nbsColors.white }}/>)
            : (<Typography fontWeight={500}>{label}</Typography>)
        }
    </Button>);
});

export default NextButton;