import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from "@mui/material/CircularProgress";

export const Overlay = React.memo(({open}) => {
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress
                    sx={{
                        color: "#fff"
                    }}
                />
            </Backdrop>
        </div>
    );
});