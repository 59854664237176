import {makeStyles} from "@mui/styles";
import {nbsColors} from "../constants/colors";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: nbsColors.lightGrey,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        // paddingBottom: '4rem',
    },
    content: {
        flex: 1,
    },
}));

const BackGroundPaper = (({children}) => {
    const classes = useStyles();

    return (<div className={classes.body}>
        <div className={classes.content}>
            {children}
        </div>
        <Footer/>
    </div>)
});

export default BackGroundPaper;