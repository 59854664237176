import {nbsColors} from "./colors";
export const buttonStyle = {
    borderRadius: '10px',
    backgroundColor: nbsColors.red,
    color: nbsColors.white,
    '&:hover': {
        backgroundColor: nbsColors.red,
        color: nbsColors.white,
    }
};
