export const noFixedAccountFoundForCashCoveredLoanRequest =
    "To proceed with this loan application, a fixed account is required";
export const defaultErrorMessage = "Something went wrong";
export const loanEligibilityMonthsAmountMessage =
    "Please select your desired loan amount below. The corresponding applicable loan durations are also listed.";
export const channel = "WEBSITE";
export const errorMessages = {
    otpIsMissing: "Please enter OTP",
    couldNotReadQrCode: "Could not read QR code.",
    otpMaxRetriesReached: "Maximum OTP retries reached",
    fixedAccountsRequired: "Dear customer, a fixed collateral account is required to apply for this loan facility."
};
export const socialMediaPages = {
    x: "https://x.com/nbsbankmw",
    facebook: "https://www.facebook.com/nbs.malawi"
}