import {
    Alert,
    Box, Button, Card, Container,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import {nbsColors} from "../constants/colors";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import * as React from "react";
import {useEffect, useState} from "react";
import CommonsAPI from "../api/CommonsAPI";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../components/Loader";
import {Header} from "../components/Header";
import {errorMessages} from "../constants/messages";
import BackButton from "../components/BackButton";
import NextButton from "../components/NextButton";
import ConnectivityChecker from "../components/ConnectivityChecker";
import BackGroundPaper from "../components/BackGroundPaper";

const maxOtpRetries = 2;

const OtpPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [loanApplication, setLoanApplication] = useState(null);
    const [otp, setOtp] = useState('');
    const [verifyingOtp, setVerifyingOtp] = useState(false)
    const [otpVerificationResponse, setOtpVerificationResponse] = useState(null)
    const [otpIsEmpty, setOtpIsEmpty] = useState(false);
    const [resendingOtp, setResendingOtp]= useState(false);
    const [otpMaxRetriesReached, setOtpMaxRetriesReached] = useState(false);
    const [otpRetries, setOtpRetries] = useState(0);

    useEffect(() => {
        if (location.state) {
            setLoanApplication(location.state);
        }
    }, [])

    const resetValues = () => {
        setVerifyingOtp(false)
        setOtpVerificationResponse(null)
        setOtpIsEmpty(false)
        setOtp('')
    }

    const resendOtpFunc = async () => {
        if (otpRetries === maxOtpRetries) {
            setOtpMaxRetriesReached(true)
        } else {
            setOtpMaxRetriesReached(false)

            if (loanApplication) {
                resetValues();
                setResendingOtp(true)


                await new CommonsAPI().validateAccount(loanApplication.national_Id, loanApplication.account_number)
                    .then((res) => {
                        setOtpVerificationResponse(res)
                    }).catch((error) => {
                        console.error(error)
                    }).finally(() => {
                        setResendingOtp(false)
                        setOtpRetries(otpRetries + 1)
                    })
            }
        }
    }

    const verifyOtp = async () => {
        setVerifyingOtp(true);

        try {
            if (otp.length <= 0) {
                //show OTP not entered
                setOtpIsEmpty(true)
            } else {
                setOtpIsEmpty(false)
                const response = await new CommonsAPI().verifyOtp(loanApplication.account_number, otp)
                    .then((res) => res)
                    .catch((err) => console.error(err))
                    .finally(() => {
                        setVerifyingOtp(false)
                    })

                setOtpVerificationResponse(response)
                if (response.status) {
                    navigate("/loans", { state: loanApplication })
                }
            }
        } catch (err) {
            console.error(err)
        } finally {
            setVerifyingOtp(false)
        }
    }

    return (<BackGroundPaper>
        <>
            <Header/>
            <ConnectivityChecker/>
            <Container maxWidth="md" sx={{ marginTop: "1rem" }} >
                <Box sx={{ borderRadius: "100px", marginTop: "1rem"}}>
                    <Card elevation={0} sx={{ borderRadius: "10px" }}>
                        {loanApplication
                            ? (<>
                                <Box p={2}>
                                    <Stack sx={{ width: '100%', mt: 1 }} spacing={2}>
                                        <Alert severity="success">
                                            <Typography fontWeight={600}>{loanApplication.otp_message}</Typography>
                                        </Alert>
                                    </Stack>
                                </Box>
                                <Box p={2}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={12}>
                                            <FormControl size="small" fullWidth>
                                                <OutlinedInput
                                                    sx={{ borderRadius: '100px', paddingRight: 0, border: `1px solid ${nbsColors.lightGrey}`, ":focus": {borderColor: 'red'}}}
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    type="password"
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    onKeyDown={async (e) => {
                                                        if (e.key === 'Enter') {
                                                            verifyOtp();
                                                        }
                                                    }}
                                                    inputProps={{ maxLength: 6 }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton sx={{
                                                                borderRadius: "100px",
                                                                color: nbsColors.blue,
                                                                ":hover": {
                                                                    color: nbsColors.blue
                                                                }
                                                            }} onClick={async () => verifyOtp()}>
                                                                <SendRoundedIcon/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            {(otpIsEmpty || otpMaxRetriesReached) && (<Box mb={2}>
                                                <Alert sx={{ borderRadius: '10px' }} severity="error" icon={false}>
                                                    <Typography fontWeight={600} color={nbsColors.red}>
                                                        {otpIsEmpty ? errorMessages.otpIsMissing : errorMessages.otpMaxRetriesReached}
                                                    </Typography>
                                                </Alert>
                                            </Box>)}

                                            {otpVerificationResponse && !otpVerificationResponse.status && (
                                                <Box mb={2}>
                                                    <Alert
                                                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                                        severity="error"
                                                        icon={false}
                                                        action={
                                                            <>
                                                                {!otpMaxRetriesReached && <>
                                                                    <Button onClick={resendOtpFunc} size="small"
                                                                            sx={{ ml: 2, whiteSpace: 'nowrap', textTransform: "none" }}>
                                                                        <Typography fontWeight={600} color={nbsColors.red}>Resend</Typography>
                                                                    </Button>
                                                                </>}
                                                            </>
                                                        }>
                                                        <Typography fontWeight={600} color={nbsColors.red}>
                                                            {otpVerificationResponse.msg}
                                                        </Typography>
                                                    </Alert>
                                                </Box>
                                            )}

                                            {(verifyingOtp || resendingOtp) && (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <Loader label={verifyingOtp ? "Verifying OTP" : "Resending OTP"}/>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Box mt={4} display="flex" justifyContent="space-between">
                                        <BackButton/>
                                        <NextButton onNextCallback={verifyOtp} label="Verify OTP"/>
                                    </Box>
                                </Box>
                            </>)
                            : ( <Box p={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Loader label={'Setting up OTP verification...'}/>
                            </Box>)
                        }
                    </Card>
                </Box>
            </Container>
        </>
    </BackGroundPaper>)
};

export default OtpPage;