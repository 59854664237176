import {
    Box,
    Button,
    Card, Container,
    ListItem,
    Typography
} from "@mui/material";
import {Header} from "../components/Header";
import {nbsColors} from "../constants/colors";
import * as React from "react";
import {useEffect, useState} from "react";
import Loader from "../components/Loader";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {defaultErrorMessage, errorMessages} from "../constants/messages";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CurrencyUtils from "../utils/CurrencyUtils";
import {useLocation, useNavigate} from "react-router-dom";
import CommonsAPI from "../api/CommonsAPI";
import {maskNumber} from "../utils/Generic";
import LoanApplication from "../structs/LoanApplication";
import BackButton from "../components/BackButton";
import ConnectivityChecker from "../components/ConnectivityChecker";
import BackGroundPaper from "../components/BackGroundPaper";
import {LoanID} from "../structs/LoanID";
import EligibilityError from "../components/EligibilityError";
import {formatToHumanReadableDate} from "../utils/DateUtils";

const currencyUtils = new CurrencyUtils();

const LoansPage = () => {
    const [loans, setLoans] = useState({});
    const [checkingEligibility, setCheckingEligibility] = useState(false)
    const [eligibilityCheckCompleted, setEligibilityCheckCompleted] = useState(false)
    const [eligibilityResponse, setEligibilityResponse] = useState(null);
    const [fetchingLoans, setFetchingLoans] = useState(false)
    const [loanApplication, setLoanApplication] = useState(null);
    const [maxHeight, setMaxHeight] = useState('');
    const [checkingFixedAccounts, setCheckingFixedAccounts] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setLoanApplication(location.state);
            fetchLoans(location.state);
        }

        function handleResize() {
            const viewportHeight = window.innerHeight;
            const percentage = 60;
            const maxHeightInPx = (percentage / 100) * viewportHeight;
            setMaxHeight(`${maxHeightInPx}px`);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchLoans = async (application) => {
        setFetchingLoans(true)
        try {
            const loans = await new CommonsAPI().fetchLoans(application.account_number)
                .then((res) => res)
                .catch((err) => console.error(err))
                .finally(() => setFetchingLoans(false))

            setLoans(loans);
        } catch (err) {
            console.error(err)
        } finally {
            setFetchingLoans(false)
        }
    }

    const goToNextPage = (eligibilityResponse, loan, fixedAccounts, loanAccount) => {
        const application = LoanApplication.fromJSON(loanApplication);
        application.loan_id = loan['LOAN_ID'];
        application.loan_type = loan['CATEGORY_DESC'];
        application.existing_amount = loan['BALANCE'];

        if (loan['LOAN_ID'] === LoanID.cash_covered) {
            const appObject = {
                application: application,
                fixedAccounts: fixedAccounts
            }
            navigate("/fixed-accounts", { state: appObject});
        } else {
            //TODO: Too much repetition - move to a common method
            if ('amount' in eligibilityResponse) {
                application.term = 1;
                application.amount = eligibilityResponse['amount']
                navigate("/charges", { state: application});
            } else {

                if (loan['TOPUP_POSSIBLE'] && loanAccount !== null) {
                    application.loan_to_top_up = loanAccount;
                }

                const data = {
                    application: application,
                    eligibility: eligibilityResponse
                }
                navigate("/eligibility", { state: data})
            }
        }
    }

    const triggerEligibilityCheck = async (loan, loanAccount) => {
        setLoanApplication(prevState => ({
            ...prevState,
            loan_id: loan["LOAN_ID"]
        }));

        setCheckingEligibility(true)
        await new CommonsAPI().eligibility(loanApplication.account_number,"", loan['LOAN_ID'])
            .then(res => {
                setEligibilityResponse(res)
                if (res.status) {
                    goToNextPage(res, loan, [], loanAccount);
                }
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setCheckingEligibility(false)
                setEligibilityCheckCompleted(true)
            })
    }

    const eligibilityResponseError = () => eligibilityResponse?.status === false;
    const eligibilityResponseErrorMessage = () => {
        if ("message" in eligibilityResponse) return eligibilityResponse.message;
        if ("msg" in eligibilityResponse) return eligibilityResponse.msg;
        return defaultErrorMessage;
    };

    useEffect(() => {
        if (location.state) {
            setLoanApplication(location.state);
        }
    }, [])

    const getCustomerName = (obj) => {
        return Object.entries(obj)[0][1]['CUSTOMER_NAME'];
    }

    const getTotalLoansAmount = (obj) => {
        const entries = Object.entries(obj);
        let totalLoanAmount = 0;

        for (let i = 0; i < entries.length; i++) {
            if (entries[i][0] === "status") {
                continue;
            }

            if (entries[i][1]['BALANCE']) {
                totalLoanAmount += entries[i][1]['BALANCE'];
            }
        }

        return totalLoanAmount;
    }

    const disableApplyForLoan = (loan) => !loan["TOPUP_POSSIBLE"] && loan["BALANCE"] > 0

    const isCashCoveredLoan = (loanId) => {
        return loanId === LoanID.cash_covered;
    }

    const triggerFixedAccountsCheck = async (loan, loanAccount) => {
        setLoanApplication(prevState => ({
            ...prevState,
            loan_id: loan["LOAN_ID"]
        }));

        setCheckingFixedAccounts(true)
        await new CommonsAPI().fetchFixedAccounts(loanApplication.account_number)
            .then(fixedAccounts => {
                if (fixedAccounts.length <= 0) {
                    setEligibilityResponse({
                        "message": errorMessages.fixedAccountsRequired,
                        "status": false
                    })
                    setEligibilityCheckCompleted(true)
                } else {
                    goToNextPage(null, loan, fixedAccounts, loanAccount);
                }
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setCheckingFixedAccounts(false)
            })
    }

    return (<BackGroundPaper>
        <>
            <Header/>
            <ConnectivityChecker/>
            <Container maxWidth="md" sx={{ marginTop: "1rem" }}>
                <Box>
                    {loans && Object.keys(loans).length > 0 && (<>
                        <Box sx={{ borderRadius: "100px", marginTop: "1rem"}}>
                            <Card elevation={0} sx={{ borderRadius: "10px" }}>
                                <Box color={nbsColors.blue} display="flex" p={2} justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <Box>
                                            <PersonOutlineIcon fontSize="large"/>
                                            <Typography fontWeight={600}>{getCustomerName(loans)}</Typography>
                                            <Typography>Acc No: {maskNumber(loanApplication.account_number)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Box>
                                            <Typography>Total Loan Balance</Typography>
                                            <Typography fontWeight={600}>
                                                {currencyUtils.formatAmountInMalawiKwacha(getTotalLoansAmount(loans))}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </>)}

                    <Box sx={{ borderRadius: "100px", marginTop: "0.5rem"}}>
                        <Card elevation={0} sx={{ borderRadius: "10px" }}>
                            {/* TODO: Account not found or invalid error [component] show with back button */}
                            <Box pl={2} pr={2}>
                                <Box>
                                    {fetchingLoans && (
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Loader label='Fetching loans'/>
                                        </Box>
                                    )}

                                    {Object.keys(loans).length > 0 && (
                                        <>
                                            {(checkingEligibility || checkingFixedAccounts) && (
                                                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <Loader label={checkingEligibility ? 'Checking eligibility' : 'Retrieving fixed accounts'}/>
                                                </Box>
                                            )}

                                            {(<>
                                                <List style={{ overflowY: "auto", maxHeight }}>
                                                    {Object.keys(loans).map((key, index) => {
                                                        if (!loans[key]["IS_INSTANT"]) {
                                                            return null;
                                                        }

                                                        const loanId = loans[key]["LOAN_ID"]

                                                        return (
                                                            <React.Fragment key={key}>
                                                                <ListItem
                                                                    disableGutters
                                                                    divider={index < Object.keys(loans).length - 1}
                                                                    secondaryAction={<React.Fragment>
                                                                        <Button
                                                                            disabled={disableApplyForLoan(loans[key])}
                                                                            sx={{
                                                                                borderRadius: '10px',
                                                                                backgroundColor: nbsColors.red,
                                                                                color: nbsColors.white,
                                                                                '&:hover': {
                                                                                    backgroundColor: disableApplyForLoan(loans[key]) ? nbsColors.lightGrey : nbsColors.red,
                                                                                    color: disableApplyForLoan(loans[key]) ? nbsColors.black : nbsColors.white,
                                                                                }
                                                                            }}
                                                                            onClick={() => {
                                                                                const loanAccount = key;
                                                                                if (isCashCoveredLoan(loanId)) {
                                                                                    triggerFixedAccountsCheck(loans[key], loanAccount)
                                                                                } else {
                                                                                    triggerEligibilityCheck(loans[key], loanAccount)
                                                                                }
                                                                            }}>
                                                                            <Typography textTransform="none">
                                                                                {loans[key]["TOPUP_POSSIBLE"] ? "Top-up" : "Apply"}
                                                                            </Typography>
                                                                        </Button>
                                                                    </React.Fragment>}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                            width: '100%'
                                                                        }}
                                                                    >
                                                                        <ListItemText
                                                                            sx={{
                                                                                flex: '1 1 auto',
                                                                                color: nbsColors.blue,
                                                                                pr: 2
                                                                            }}
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    color={nbsColors.red}
                                                                                    fontWeight={550}
                                                                                    sx={{
                                                                                        wordBreak: 'break-word'
                                                                                    }}
                                                                                >
                                                                                    {loans[key]["CATEGORY_DESC"]}
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <>
                                                                                    <Typography variant="body1" color={nbsColors.blue}>
                                                                                        {`Balance: `}
                                                                                        <strong>
                                                                                            {currencyUtils.formatAmountInMalawiKwacha(
                                                                                                Number(loans[key]["BALANCE"]).toLocaleString())}
                                                                                        </strong>
                                                                                    </Typography>
                                                                                    {Number(loans[key]["REPAYMENT_AMOUNT"]) > 0 && (
                                                                                        <>
                                                                                            <Typography variant="body1" color={nbsColors.blue} >
                                                                                                {`Next installment: `}
                                                                                                <strong>
                                                                                                    {currencyUtils.formatAmountInMalawiKwacha(
                                                                                                        Number(loans[key]["REPAYMENT_AMOUNT"]).toLocaleString())}
                                                                                                </strong>
                                                                                            </Typography>
                                                                                        </>
                                                                                    )}
                                                                                    {/*{loans[key]["MATURITY_DATE"] && (*/}
                                                                                    {/*    <>*/}
                                                                                    {/*        <Typography variant="body1" color={nbsColors.blue} >*/}
                                                                                    {/*            {`Due date: `}*/}
                                                                                    {/*            <strong>*/}
                                                                                    {/*                {formatToHumanReadableDate(loans[key]["MATURITY_DATE"])}*/}
                                                                                    {/*            </strong>*/}
                                                                                    {/*        </Typography>*/}
                                                                                    {/*    </>*/}
                                                                                    {/*)}*/}
                                                                                    {loans[key]["REPAYMENT_DATE"] && (
                                                                                        <Typography variant="body1"
                                                                                                    color={nbsColors.blue}>
                                                                                            {`Due on `}
                                                                                            <strong>
                                                                                                {formatToHumanReadableDate(loans[key]["REPAYMENT_DATE"])}
                                                                                            </strong>
                                                                                        </Typography>
                                                                                    )}

                                                                                    {/*{Number(loans[key]["ARREARS"]) > 0 && (*/}
                                                                                    {/*    <>*/}
                                                                                    {/*        <Typography variant="body1" color={nbsColors.blue} >*/}
                                                                                    {/*            {`Arrears: `}*/}
                                                                                    {/*            <strong>*/}
                                                                                    {/*                {currencyUtils.formatAmountInMalawiKwacha(*/}
                                                                                    {/*                    Number(loans[key]["ARREARS"]).toLocaleString())}*/}
                                                                                    {/*            </strong>*/}
                                                                                    {/*        </Typography>*/}
                                                                                    {/*    </>*/}
                                                                                    {/*)}*/}

                                                                                    {currencyUtils.isMoreThanZero(loans[key]['ARREARS']) && (
                                                                                        <>
                                                                                            <Typography variant="body1"
                                                                                                        color={nbsColors.blue}>
                                                                                                {`Arrears: `}
                                                                                                <strong>
                                                                                                    {currencyUtils.formatAmountInMalawiKwacha(
                                                                                                        Number(loans[key]["ARREARS"]).toLocaleString())}
                                                                                                </strong>
                                                                                            </Typography>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </ListItem>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </List>
                                            </>)}

                                            {eligibilityResponse && eligibilityCheckCompleted && eligibilityResponseError() && (<>
                                                <Box pt={2} pb={2}>
                                                    {!checkingEligibility &&
                                                        (<EligibilityError errorMessage={eligibilityResponseErrorMessage()}/>)}
                                                    <Box display="flex" justifyContent="space-between" mt={2}>
                                                        <BackButton/>
                                                    </Box>
                                                </Box>
                                            </>)}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Container>
        </>
    </BackGroundPaper>);
}

export default LoansPage;