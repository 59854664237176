import {channel} from "../constants/messages";

class LoanApplication {
    national_Id;
    account_number;
    otp_message;
    fixed_account = null;
    loan_id;
    loan_type;
    amount;
    term;
    branch_code;
    loan_to_top_up = "";
    existing_amount = 0;
    loan_purpose;
    channel = channel;

    static fromJSON(json) {
        const loanApp = new LoanApplication();
        loanApp.national_Id = json.national_Id;
        loanApp.account_number = json.account_number;
        loanApp.otp_message = json.otp_message;
        loanApp.fixed_account = json.fixed_account;
        loanApp.loan_id = json.loan_id;
        loanApp.loan_type = json.loan_type;
        loanApp.amount = json.amount;
        loanApp.term = json.term;
        loanApp.branch_code = json.branch_code;
        loanApp.loan_to_top_up = json.loan_to_top_up;
        loanApp.existing_amount = json.existing_amount;
        loanApp.loan_purpose = json.loan_purpose;
        loanApp.channel = channel;
        return loanApp;
    }
}

export default LoanApplication;