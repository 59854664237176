import moment from "moment";
import {NationalID} from "../structs/NationalID";

export const createNationalIdFromIDScannedData = (scanData) => {
    let exp_date = scanData.replace(/</g, " ").split("~")[2];
    exp_date = exp_date.match(/(M|F)([0-9]+)/)[2];

    let charLength = scanData.split("~").length;
    let partsDiffFactor = 0;
    if (charLength === 11) {
        partsDiffFactor = 1;
    }

    let details = {
        "firstname": scanData.replace(/</g, " ").split("~")[6],
        "surname": scanData.replace(/</g, " ").split("~")[4],
        "other_name": scanData.replace(/</g, " ").split("~")[7],
        "id_number": scanData.replace(/</g, " ").split("~")[5],
        "gender": scanData.replace(/</g, " ").split("~")[8 - partsDiffFactor],
        "id_issue_date": new Date(scanData.replace(/</g, " ").split("~")[10 - partsDiffFactor]).toDateString().toUpperCase(),
        "date_of_birth": new Date(scanData.replace(/</g, " ").split("~")[9 - partsDiffFactor]).toDateString().toUpperCase(),
        "id_expiry_date": new Date("20" + exp_date.substring(0, 2) + "/" + exp_date.substring(2, 4) + "/" + exp_date.substring(4, 6)).toDateString().toUpperCase()
    }

    details.date_of_birth  = moment(details.date_of_birth).format("YYYY-MM-DD");

    return new NationalID(
        details.firstname,
        details.surname,
        details.other_name,
        details.id_number,
        details.gender,
        details.id_issue_date,
        details.date_of_birth,
        details.id_expiry_date
    );
}

export const createEmptyNationalId = () => {
    return new NationalID(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
    );
}