import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {Box, Button, Grid, Typography} from "@mui/material";
import {nbsColors} from "../constants/colors";
import {buttonStyle} from "../constants/styles";

const ResultText = React.memo(({label, value}) => {
    return (
        <>
            <ListItem
                disableGutters
                disablePadding
                secondaryAction={<Typography color={nbsColors.blue}>{value}</Typography>}
            >
                <ListItemText primary={<Typography color={nbsColors.blue}>{label.toUpperCase()}</Typography>} />
            </ListItem>
        </>
    );
});

const NationalIDScannedDetails = React.memo(({nationalId}) => {
    return (
       <>
           <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
               <Box display="flex" flexDirection="column">
                   <ResultText label="First Name" value={nationalId.firstName}/>
                   <ResultText label="Surname" value={nationalId.surname}/>
                   <ResultText label="Date of Birth" value={nationalId.dateOfBirth}/>
                   <ResultText label="Gender" value={nationalId.gender}/>
                   <ResultText label="ID Number" value={nationalId.idNumber}/>
                   <ResultText label="Issue Date" value={nationalId.issueDate}/>
                   <ResultText label="Expiry Date" value={nationalId.expiryDate}/>
               </Box>
           </List>
       </>
    );
});

export default NationalIDScannedDetails;