export class NationalID {
    firstName = null;
    surname = null;
    otherName = null;
    idNumber = null;
    gender = null;
    issueDate = null;
    dateOfBirth = null;
    expiryDate = null;

    constructor(firstName, surname, otherName, idNumber, gender, issueDate, dateOfBirth, expiryDate) {
        this.firstName = firstName;
        this.surname = surname;
        this.otherName = otherName;
        this.idNumber = idNumber;
        this.gender = gender;
        this.issueDate = issueDate;
        this.dateOfBirth = dateOfBirth;
        this.expiryDate = expiryDate;
    }

    isOk() {
        return this.firstName !== null &&
            this.surname !== null &&
            this.idNumber !== null &&
            this.issueDate !== null &&
            this.expiryDate !== null &&
            this.dateOfBirth !== null;
    }
}