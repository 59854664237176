import React from 'react';
import { makeStyles } from '@mui/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import {nbsColors} from "../constants/colors";

const useStyles = makeStyles((theme) => ({
    animationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    checkmarkCircle: {
        stroke: '#4bb71b',
        strokeWidth: 2,
        strokeDasharray: 166,
        strokeDashoffset: 166,
        fill: 'none',
        animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
    },
    checkmarkCircleError: {
        stroke: nbsColors.errorRed,
        strokeWidth: 2,
        strokeDasharray: 166,
        strokeDashoffset: 166,
        fill: 'none',
        animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
    },
    checkmarkCheck: {
        stroke: '#4bb71b',
        strokeWidth: 2,
        strokeDasharray: 48,
        strokeDashoffset: 48,
        fill: 'none',
        animation: '$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
    },
    checkmarkError: {
        stroke: nbsColors.errorRed,
        strokeWidth: 2,
        strokeDasharray: 48,
        strokeDashoffset: 48,
        fill: 'none',
        animation: '$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
    },
    '@keyframes stroke': {
        '100%': {
            strokeDashoffset: 0,
        },
    },
}));

const CheckmarkIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 52 52" style={{ fontSize: '6em' }}>
        <circle className={props.classes.checkmarkCircle} cx="26" cy="26" r="25" />
        <path className={props.classes.checkmarkCheck} d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </SvgIcon>
);

const FailureAnimation = (props) => (
    <SvgIcon {...props} viewBox="0 0 52 52" style={{ fontSize: '6em' }}>
        <circle className={props.classes.checkmarkCircleError} cx="26" cy="26" r="25" />
        <path className={props.classes.checkmarkError} d="M16 16 36 36 M16 36 36 16" />
    </SvgIcon>
);

const ResponseSuccessAnimation = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.animationContainer} elevation={0}>
            <CheckmarkIcon classes={classes} fontSize="large" />
        </Paper>
    );
};

const ResponseFailureAnimation = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.animationContainer} elevation={0}>
            <FailureAnimation classes={classes} fontSize="large" />
        </Paper>
    );
};

export { ResponseSuccessAnimation, ResponseFailureAnimation };
