const loanPurposes = [
    "Personal Needs",
    "Business Expenses",
    "Education",
    "Gift",
    "Bills",
    "Food",
    "Medical Expense",
    "Salary",
    "Donation",
    "Legal Obligation",
    "Travel Expenses"
]

export default loanPurposes;