import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import NBSBankTerms from "./NBSBankTerms";
import CustomButton from "./CustomButton";
import {nbsColors} from "../constants/colors";
import {Grid} from "@mui/material";
import ThumbUpOffAltRoundedIcon from '@mui/icons-material/ThumbUpOffAltRounded';
export const TermsAndConditionsDialog = (({open, setOpen, onAcceptCallback}) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                <NBSBankTerms/>
                <DialogActions>
                    <Grid p={2} container spacing={2} display="flex" justifyContent="space-between">
                        <Grid item>
                            <CustomButton
                                color={nbsColors.blue}
                                bgColor={nbsColors.lightGrey}
                                label="Cancel"
                                onClickCallback={handleClose}
                            />
                        </Grid>
                        <Grid item>
                            <CustomButton
                                icon={<ThumbUpOffAltRoundedIcon />}
                                label="I Accept"
                                onClickCallback={onAcceptCallback}
                            />
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});
