const loanProducts = [
    {
        "name": "Kachangu Personal loan",
        "description": "Credit facility for individual customers with a maximum " +
            "term of 60 months. It has interest rate of Base Rate + Risk-Based margin."
    },
    {
        "name": "Kachangu Payday Loan",
        "description": "An advance given to salaried employees to be repaid on the next salary date. " +
            "Its commission based with zero interest rate. It has a limit of 40% of disposable " +
            "net monthly salary (after all existing monthly loan deductions)."
    },
    {
        "name": "Cash covered loan",
        "description": "Loans secured by cash. Customer must have a fixed collateral account whereby " +
            "the maximum applicable amount is 80% of the cash collateral and maximum term is 24 Months."
    },
    {
        "name": "Kachangu Overdraft",
        "description": "Credit facility for High Net Worth individuals with a current account. It enables " +
            "account overdraw for a maximum period of 12 months."
    }
]

export default loanProducts;