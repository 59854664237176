import {Header} from "../components/Header";
import ConnectivityChecker from "../components/ConnectivityChecker";
import * as React from "react";
import BackGroundPaper from "../components/BackGroundPaper";
import {
    Box,
    Card,
    CardContent, Checkbox,
    Container,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography
} from "@mui/material";
import {nbsColors} from "../constants/colors";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import BackButton from "../components/BackButton";
import NextButton from "../components/NextButton";
import Loader from "../components/Loader";
import CommonsAPI from "../api/CommonsAPI";
import LoanApplication from "../structs/LoanApplication";
import {LoanID} from "../structs/LoanID";
import EligibilityError from "../components/EligibilityError";
import {defaultErrorMessage} from "../constants/messages";

const FixedAccountsPage = (({}) => {
    const [loanApplication, setLoanApplication] = useState(null);
    const [fixedAccounts, setFixedAccounts] = useState([]);
    const [initializing, setInitializing] = useState(true);
    const [maxHeight, setMaxHeight] = useState('');
    const [checkingEligibility, setCheckingEligibility] = useState(false)
    const [eligibilityCheckCompleted, setEligibilityCheckCompleted] = useState(false)
    const [eligibilityResponse, setEligibilityResponse] = useState(null);

    const [checkedIndex, setCheckedIndex] = React.useState(null);
    const handleToggle = (value) => () => setCheckedIndex(value);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setLoanApplication(location.state.application);
            setFixedAccounts(location.state.fixedAccounts);
        }

        function handleResize() {
            const viewportHeight = window.innerHeight;
            const percentage = 60;
            const maxHeightInPx = (percentage / 100) * viewportHeight;
            setMaxHeight(`${maxHeightInPx}px`);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (loanApplication && fixedAccounts.length > 0) {
            setInitializing(false)
        }
    }, [loanApplication, fixedAccounts])

    const triggerEligibilityCheck = async (fixedAccount) => {
        setCheckingEligibility(true)

        await new CommonsAPI().eligibility(loanApplication.account_number, fixedAccount, LoanID.cash_covered)
            .then(res => {
                setEligibilityResponse(res)
                if (res.status) {
                    goToNextPage(res, fixedAccount);
                }
            }).catch(err => {
                console.error(err)
            }).finally(() => {
                setCheckingEligibility(false)
                setEligibilityCheckCompleted(true)
            })
    }

    const eligibilityResponseError = () => eligibilityResponse?.status === false;
    const eligibilityResponseErrorMessage = () => {
        if ("message" in eligibilityResponse) return eligibilityResponse.message;
        if ("msg" in eligibilityResponse) return eligibilityResponse.msg;
        return defaultErrorMessage;
    };

    const goToNextPage = (eligibilityResponse, fixedAccount) => {
        const application = LoanApplication.fromJSON(loanApplication);
        application.fixed_account = fixedAccount;

        const data = {
            application: application,
            eligibility: eligibilityResponse
        }

        navigate("/eligibility", { state: data})
    }

    return (<BackGroundPaper>
        <>
            <Header/>
            <ConnectivityChecker/>
            <Container maxWidth="md" sx={{ marginTop: "1rem" }} >
                <Box sx={{ borderRadius: "100px", marginTop: "1rem"}}>
                    <Card elevation={0} sx={{ borderRadius: "10px" }}>
                        <CardContent>
                            <Box mb={2} display="flex" justifyContent="flex-start">
                                <Typography
                                    sx={{
                                        color: nbsColors.blue,
                                        fontWeight: "600"
                                    }}>
                                    {`Choose fixed account`}
                                </Typography>
                            </Box>

                            <ListItem
                                secondaryAction={<Typography fontWeight={600} color={nbsColors.blue}>
                                    Balance (MWK)
                                </Typography>}
                                disablePadding>
                                <ListItemText primary={<Typography fontWeight={600} color={nbsColors.blue}>
                                    Account
                                </Typography>} />
                            </ListItem>

                            <Box pl={2} pr={2}>
                                <Box>
                                    {checkingEligibility && (
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Loader label='Checking eligibility'/>
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box mb={2} p={1} borderRadius="10px" sx={{ border: `1px solid ${nbsColors.lightGrey}` }}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {fixedAccounts.map((key, index) => {
                                        return (
                                            <ListItem
                                                divider={index < fixedAccounts.length - 1}
                                                sx={{
                                                    backgroundColor: index === checkedIndex ? nbsColors.lightGrey : null,
                                                }}
                                                key={index}
                                                secondaryAction={<Typography color={nbsColors.blue}>
                                                    {`${Number(key?.balance).toLocaleString()}`}
                                                </Typography>}
                                                disablePadding>
                                                <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checkedIndex === index}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': key }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={key} primary={<>
                                                        <Typography color={nbsColors.blue}>
                                                            {key?.accountNumber}
                                                        </Typography>
                                                    </>} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>

                            {eligibilityResponse && eligibilityCheckCompleted && eligibilityResponseError() && (<>
                                <Box pt={2} pb={2}>
                                    {!checkingEligibility && (<EligibilityError errorMessage={eligibilityResponseErrorMessage()}/>)}
                                </Box>
                            </>)}

                            <Box mt={4} display="flex" justifyContent="space-between">
                                <BackButton/>
                                <NextButton disabled={checkedIndex === null} onNextCallback={() => {
                                    triggerEligibilityCheck(fixedAccounts[checkedIndex]?.accountNumber)
                                }}/>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            </>
    </BackGroundPaper>);
});

export default FixedAccountsPage;