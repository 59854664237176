export const isEmptyArray = (arr) => {
    if (arr === undefined ||  arr === null) {
        return true;
    }
    return arr.length <= 0;
}

export const getDefaultAccountInfo = () => {
    return {
        accountName: null,
        accountType: null,
        branchName: null,
        phoneNumber: null,
        kycCompliant: null,
        fullAccountInformation: null,
        Id: {
            firstName: null,
            surname: null,
            dob: null,
            type: null,
            expiryDate: null,
            gender: null,
            maritalStatus: null,
            idNumber: null,
            issueDate: null,
        }
    }
}

export const numberToWordsMalawi = (amount, include_only = false) => {
    const ones = [
        '',
        'ONE',
        'TWO',
        'THREE',
        'FOUR',
        'FIVE',
        'SIX',
        'SEVEN',
        'EIGHT',
        'NINE',
        'TEN',
        'ELEVEN',
        'TWELVE',
        'THIRTEEN',
        'FOURTEEN',
        'FIFTEEN',
        'SIXTEEN',
        'SEVENTEEN',
        'EIGHTEEN',
        'NINETEEN'
    ];

    const tens = [
        '',
        '',
        'TWENTY',
        'THIRTY',
        'FORTY',
        'FIFTY',
        'SIXTY',
        'SEVENTY',
        'EIGHTY',
        'NINETY'
    ];

    const numToWords = (num) => {
        if (num === 0) return '';
        else if (num < 20) return ones[num] + ' ';
        else if (num < 100) return tens[Math.floor(num / 10)] + ' ' + ones[num % 10] + ' ';
        else return ones[Math.floor(num / 100)] + 'HUNDRED ' + numToWords(num % 100);
    };

    const toWords = (num) => {
        if (num === 0) return 'ZERO';
        let words = '';
        if (num >= 1000000) {
            words += toWords(Math.floor(num / 1000000)) + ' MILLION ';
            num %= 1000000;
        }
        if (num >= 1000) {
            words += toWords(Math.floor(num / 1000)) + ' THOUSAND ';
            num %= 1000;
        }
        if (num >= 100) {
            words += numToWords(Math.floor(num / 100)) + ' HUNDRED ';
            num %= 100;
        }
        if (num > 0) {
            words += numToWords(num);
        }
        return words.trim();
    };

    const roundedAmount = Math.round(amount * 100);
    const kwacha = toWords(Math.floor(roundedAmount / 100)) + ' MALAWI KWACHA ';

    const roundedTambala = roundedAmount % 100;
    const tambala = toWords(roundedTambala) + ' TAMBALA';

    let inWords = kwacha;
    if (roundedTambala !== 0) {
        inWords = inWords + tambala;
    }

    const amountFormatted = 'MWK' + Number(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    let only = '';
    if (include_only) {
        only = ' Only';
    }

    return {
        formatted: amountFormatted,
        inWords: capitalizeWords(inWords) + only
    };
}

const capitalizeWords = (inputString) => {
    const words = inputString.split(" ");
    const capitalizedWords = [];

    for (const word of words) {
        if (word.length > 0) {
            const capitalizedWord = word[0].toUpperCase() + word.slice(1).toLowerCase();
            capitalizedWords.push(capitalizedWord);
        }
    }

    return capitalizedWords.join(" ");
}

export const formatDateWithOrdinal = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const dayWithOrdinal = (day) => {
        if (day >= 11 && day <= 13) {
            return day + 'th';
        } else {
            const lastDigit = day % 10;
            switch (lastDigit) {
                case 1: return day + 'st';
                case 2: return day + 'nd';
                case 3: return day + 'rd';
                default: return day + 'th';
            }
        }
    }

    return dayWithOrdinal(day) + ' ' + month + ', ' + year;
}
export const getDayMonthYearFromTodayAfterAddingMonths = (salaryDate, monthsToAdd) => {
    const currentDate = new Date();
    let monthsToPayOff = Number(monthsToAdd);

    if (currentDate.getDate() <= process.env.REACT_APP_SALARY_CUTOFF_DATE) {
        monthsToPayOff -= 1;
    }
    currentDate.setMonth(currentDate.getMonth() + monthsToPayOff);

    // Extract month, and year
    const month = currentDate.toLocaleString('en-US', { month: 'long' });
    const year = currentDate.getFullYear();

    return `${salaryDate} ${month} ${year}`;
}

const tokenKeyName = "token";

export const getToken = () => {
    return localStorage.getItem(tokenKeyName);
}


export const toCamelCase = (key) => {
    return key.replace(/_([a-z])/g, function(match, letter) {
        return letter.toUpperCase();
    });
}

export const maskNumber = (inputString) => {
    if (inputString.length < 8) {
        return inputString;
    }
    const startIndex = Math.floor((inputString.length - 4) / 2);

    return inputString.substring(0, startIndex) + "XXXX" + inputString.substring(startIndex + 4);
}