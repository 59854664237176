import { createTheme, ThemeProvider } from '@mui/material/styles';
import {nbsColors} from "../constants/colors";

const TypographyTheme = createTheme({
    typography: {
       fontFamily: "sans-serif"
    },
    palette: {
        background: {
            default: nbsColors.lightGrey,
        },
    },
});

export default TypographyTheme;
