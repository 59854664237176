import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
    InputAdornment,
    createTheme,
    ThemeProvider,
    Paper,
    Divider,
    Backdrop
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {nbsColors} from "../constants/colors";
import { CheckCircleOutline, HighlightOffOutlined } from '@mui/icons-material';
import {Header} from "../components/Header";
import BackButton from "../components/BackButton";
import {toCamelCase} from "../utils/Generic";
import NextButton from "../components/NextButton";
import {TermsAndConditionsDialog} from "../components/TermsAndConditionsDialog";
import Loader from "../components/Loader";
import CommonsAPI from "../api/CommonsAPI";
import {makeStyles} from "@mui/styles";
import {defaultErrorMessage} from "../constants/messages";
import ConnectivityChecker from "../components/ConnectivityChecker";
import BackGroundPaper from "../components/BackGroundPaper";

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: nbsColors.grey,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: nbsColors.blue,
                        },
                    },
                },
            },
        },
    },
});

const useStyles = makeStyles({
    cardContainer: {
        position: 'relative',
        minHeight: '200px',
    },
    loaderContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
    },
});

export const ChargesPage = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();

    const [inputAmount, setInputAmount] = React.useState(null);
    const [formattedInputAmount, setFormattedInputAmount] = React.useState(null);
    const [maximumApplicableAmount, setMaximumApplicableAmount] = React.useState(null);
    const [minimumApplicableAmount, setMinimumApplicableAmount] = React.useState(null);
    const [monthlyInstallments, setMonthlyInstallments] = React.useState(null);

    const [isValid, setIsValid] = React.useState(true);
    const [loanAmountOutOfRangeErrorMsg, setLoanAmountOutOfRangeErrorMsg] = React.useState("");

    const [loanCharges, setLoanCharges] = React.useState(null);
    const [recalculatingAssociatedCharges, setRecalculatingAssociatedCharges] = React.useState(null);

    const [loanInterest, setLoanInterest] = React.useState(null);
    const [showTermsAndConditions, setShowTermsAndConditions] = React.useState(false);
    const [applyingForLoan, setApplyingForLoan] = React.useState(false);
    const [loanApplication, setLoanApplication] = useState(null);

    const handleAmountChange = (event) => {
        const cleanValue = event.target.value.replace(/\D/g, '');
        let _newValue = parseFloat(cleanValue);
        const newValue = isNaN(_newValue) ? 0.00 : _newValue;

        setInputAmount(newValue);
        setFormattedInputAmount(newValue.toLocaleString());

        const errorMsg = getOutOfRangeErrorMessage(newValue);
        setIsValid(errorMsg.length === 0);
        setLoanAmountOutOfRangeErrorMsg(errorMsg);
    };

    const getOutOfRangeErrorMessage = (_amount) => {
        const min = minimumApplicableAmount;
        const max = maximumApplicableAmount;
        if (_amount < min) {
            return `Amount is below minimum of MKW${min.toLocaleString()}`;
        } else if (_amount > max) {
            return `Amount is above maximum of MKW${max.toLocaleString()}`;
        }
        return "";
    }

    useEffect(() => {
        try {
            if (location.state) {
                setInputAmount(location.state.amount);
                setFormattedInputAmount(parseFloat(location.state.amount).toLocaleString());
                setLoanApplication(location.state);
                calculateAssociatedCharges(
                    location.state.account_number,
                    location.state.term,
                    location.state.loan_id,
                    location.state.amount
                )

                fetchLoanTypes(location.state);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        if (inputAmount !== null) {
            if (loanApplication) {
                calculateAssociatedCharges(
                    loanApplication.account_number,
                    loanApplication.term,
                    loanApplication.loan_id,
                    inputAmount
                )
            }
        }
    }, [inputAmount]);


    const chopObjectIntoArray = (data) => {
        const maxObjectsPerList = 2;
        const result = [];

        let currentList = [];
        for (const key in data) {
            currentList.push([key, data[key]]);

            if (currentList.length === maxObjectsPerList) {
                result.push(currentList);
                currentList = [];
            }
        }

        if (currentList.length > 0) {
            result.push(currentList);
        }

        return result;
    }

    const getNetCredit = () => {
        const totalChargesAmount = Object.values(loanCharges).reduce((acc, fee) => acc + fee, 0)
        return inputAmount - totalChargesAmount;
    }
    const classes = useStyles();

    const calculateAssociatedCharges = async (accountNumber, loanTerm, loanId, amount) => {
        if (isValid) {
            setRecalculatingAssociatedCharges(true)
            try {
                const [charges, mInstallments, interest] = await Promise.all([
                    fetchCharges(accountNumber, loanId, amount),
                    fetchMonthlyInstallments(accountNumber, loanTerm, loanId, amount),
                    fetchInterest(accountNumber, loanId)
                ]);
                setLoanCharges(charges);
                setMonthlyInstallments(Object.entries(mInstallments)[0][1])
                setLoanInterest(interest['interest'])
            } catch (err) {
                console.error(err)
            } finally {
                setTimeout(() => {
                    setRecalculatingAssociatedCharges(false)
                }, 1000)
            }
        }
    }

    const fetchCharges = async (accountNumber, loanId, amount) => {
        return await new CommonsAPI().charges(
            accountNumber,
            loanId,
            amount
        )
    }

    const fetchMonthlyInstallments = async (accountNumber, loanTerm, loanId, amount) => {
        return await new CommonsAPI().monthlyInstallments(
            accountNumber,
            loanTerm,
            loanId,
            amount
        )
    }

    const fetchInterest = async (accountNumber, loanId) => {
        return await new CommonsAPI().interest(
            accountNumber,
            loanId,
        );
    }

    const fetchLoanTypes = async (lApplication) => {
        const loanTypes = await new CommonsAPI().fetchLoanTypes();

        const loanType = loanTypes.find((loanType) => loanType?.id === lApplication?.loan_id);

        if (loanType) {
            setMinimumApplicableAmount(loanType.min);
            setMaximumApplicableAmount(lApplication.amount);
        } else {
            console.error("Loan type not found. Will cause issues");
        }

    }

    const triggerShowTermsAndConditions = () => {
        setShowTermsAndConditions(true);
    }
    
    const applyForLoan = async () => {
        setApplyingForLoan(true);
        let response = {
            status: false,
            msg: defaultErrorMessage
        };

        try {
            const loanApplicationObj = { ...loanApplication };

            delete loanApplicationObj.existing_amount;
            delete loanApplicationObj.loan_type;
            delete loanApplicationObj.national_Id;
            delete loanApplicationObj.otp_message;

            loanApplicationObj.amount = inputAmount;

            response = await new CommonsAPI().apply(loanApplicationObj);
        } catch (err) {
            console.error(err)
        } finally {
            setApplyingForLoan(false)
        }

        navigate('/finish', { state: response })
    }

    return (
       <BackGroundPaper>
           <>
               <Header/>
               <ConnectivityChecker/>
               <Container sx={{ marginTop: "1rem" }}>
                   <TermsAndConditionsDialog
                       open={showTermsAndConditions}
                       setOpen={setShowTermsAndConditions}
                       onAcceptCallback={async () => {
                           setShowTermsAndConditions(false)
                           await applyForLoan()
                       }}/>

                   <Box sx={{ borderRadius: "10px", marginTop: "1rem" }}>
                       <Card elevation={0} sx={{ borderRadius: "10px" }}>
                           <CardContent>
                               {loanApplication !== null && (
                                   <>
                                       <Box mb={1} display="flex" justifyContent="space-between">
                                           <Typography
                                               sx={{
                                                   color: nbsColors.blue,
                                                   fontWeight: "600"
                                               }}>
                                               {`${loanApplication.loan_type}`}
                                           </Typography>
                                           <Typography
                                               sx={{
                                                   color: nbsColors.blue,
                                                   fontWeight: "600"
                                               }}>
                                               {`${loanApplication.term} month${loanApplication.term === 1 ? '' : 's'}`}
                                           </Typography>
                                       </Box>
                                   </>
                               )}

                               <Grid container spacing={1}>
                                   <Grid item xs={true}>
                                       {inputAmount !== null && (
                                           <ThemeProvider theme={theme}>
                                               <div>
                                                   <TextField
                                                       fullWidth
                                                       sx={{
                                                           mt: "1rem",
                                                           color: nbsColors.blue
                                                       }}
                                                       label="Amount"
                                                       value={formattedInputAmount}
                                                       onChange={handleAmountChange}
                                                       inputProps={{
                                                           maxLength: 15
                                                       }}
                                                       InputProps={{
                                                           startAdornment: <InputAdornment position="start">MWK</InputAdornment>,
                                                           endAdornment: (
                                                               <InputAdornment position="end">
                                                                   <IconButton disabled>
                                                                       {isValid ? (
                                                                           <CheckCircleOutline style={{ color: 'green' }} />
                                                                       ) : (
                                                                           <HighlightOffOutlined style={{ color: 'red' }} />
                                                                       )}
                                                                   </IconButton>
                                                               </InputAdornment>
                                                           ),
                                                       }}
                                                   />
                                               </div>
                                           </ThemeProvider>
                                       )}

                                       {loanAmountOutOfRangeErrorMsg && (
                                           <>
                                               <Typography
                                                   mt={1}
                                                   mb={1}
                                                   fontWeight={600}
                                                   sx={{ color: nbsColors.red }}
                                                   variant="body1">
                                                   {loanAmountOutOfRangeErrorMsg}
                                               </Typography>
                                           </>
                                       )}
                                   </Grid>
                               </Grid>

                               <div style={{
                                   marginTop: "0.5rem",
                                   filter: !isValid ? 'blur(3px)' : 'none',
                                   pointerEvents: !isValid ? 'none' : 'auto'
                               }}>

                                   <Backdrop
                                       sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                       open={applyingForLoan}
                                       onClick={() => {}}
                                   >
                                       <Box p={2} sx={{ backgroundColor: nbsColors.white }}>
                                           <Box display="flex" flexDirection="column">
                                               <Loader/>
                                               <Typography color={nbsColors.blue} fontWeight={600}>
                                                   Submitting your application. Please wait...
                                               </Typography>
                                           </Box>
                                       </Box>
                                   </Backdrop>

                                   <Grid container spacing={1}>
                                       <Grid item xs={true}>
                                           <Card className={classes.cardContainer} elevation={1} sx={{ border: `1px solid ${nbsColors.lightGrey}` }}>
                                               {recalculatingAssociatedCharges && (<div className={classes.loaderContainer}>
                                                   <Loader size={30}/>
                                               </div>)}

                                               <CardContent>
                                                   {!recalculatingAssociatedCharges && (<>
                                                       <Grid container spacing={1} sx={{ color: nbsColors.blue }}>
                                                           {!loanCharges
                                                               ? (<Box display="flex" justifyContent="center">
                                                                   <Loader size={20}/>
                                                               </Box>)
                                                               : (<>
                                                                   {chopObjectIntoArray(loanCharges).map((cd, index) => {
                                                                       return <Grid item xs={true}>
                                                                           {cd.length > 0 && (
                                                                               <div>
                                                                                   <Typography>{toCamelCase(cd[0][0])}</Typography>
                                                                                   <Typography sx={{ fontWeight: "600" }}>
                                                                                       {`MWK ${parseFloat(cd[0][1]).toLocaleString("en-US")}`}
                                                                                   </Typography>
                                                                               </div>
                                                                           )}

                                                                           {cd.length > 1 && (
                                                                               <div style={{ marginTop: "1rem" }}>
                                                                                   <Typography>{toCamelCase(cd[1][0])}</Typography>
                                                                                   <Typography sx={{ fontWeight: "600" }}>
                                                                                       {`MWK ${parseFloat(cd[1][1]).toLocaleString("en-US")}`}
                                                                                   </Typography>
                                                                               </div>
                                                                           )}
                                                                       </Grid>
                                                                   })}
                                                               </>)
                                                           }
                                                       </Grid>
                                                       {loanApplication && (<>
                                                           <div style={{ marginTop: "1rem" }}>
                                                               <Box p={2}>
                                                                   <Divider/>
                                                               </Box>
                                                               <Paper elevation={0} sx={{ padding: "2px", color: nbsColors.blue }}>
                                                                   <Grid container spacing={2}>
                                                                       <Grid item xs={6}>
                                                                           <Typography>
                                                                               Total:
                                                                               <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                                                                                MWK {`${parseFloat(inputAmount + loanApplication.existing_amount).toLocaleString("en-US")}`}
                                                                            </span>
                                                                           </Typography>

                                                                           <div style={{ marginTop: "0.5rem" }}/>

                                                                           {loanCharges && (<Typography>
                                                                               Net credit:
                                                                               <span style={{ fontWeight: "600", paddingLeft: "5px"}}>
                                                                                MWK {`${parseFloat(getNetCredit()).toLocaleString("en-US")}`}
                                                                            </span>
                                                                           </Typography>)}
                                                                       </Grid>

                                                                       <Grid item xs={6}>
                                                                           {monthlyInstallments !== null && (
                                                                               <>
                                                                                   <Typography>
                                                                                       Monthly installment:
                                                                                       <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                                                                                        MWK {`${parseFloat(monthlyInstallments).toLocaleString("en-US")}`}
                                                                                    </span>
                                                                                   </Typography>
                                                                               </>
                                                                           )}

                                                                           <div style={{ marginTop: "0.5rem" }}/>

                                                                           {loanInterest !== null && (
                                                                               <>
                                                                                   <Typography>
                                                                                       Interest:
                                                                                       <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                                                                                        {`${loanInterest}%`}
                                                                                    </span>
                                                                                   </Typography>
                                                                               </>
                                                                           )}
                                                                       </Grid>
                                                                   </Grid>

                                                               </Paper>
                                                           </div>
                                                       </>)}
                                                   </>)}
                                               </CardContent>
                                           </Card>
                                       </Grid>
                                   </Grid>
                               </div>
                               <Box mt={4} display="flex" justifyContent="space-between">
                                   <BackButton/>
                                   <NextButton disabled={recalculatingAssociatedCharges || !isValid} onNextCallback={triggerShowTermsAndConditions}/>
                               </Box>
                           </CardContent>
                       </Card>
                   </Box>
               </Container>
           </>
       </BackGroundPaper>
    );
});