import React from "react";
import {Button, Typography} from "@mui/material";
import {nbsColors} from "../constants/colors";
import CircularProgress from "@mui/material/CircularProgress";

const CustomButton = React.memo(({
                                     onClickCallback,
                                     label = "",
                                     icon = null,
                                     loaderEffect = false,
                                     bgColor = nbsColors.red,
                                     color = nbsColors.white}) => {
    return (<Button
        onClick={onClickCallback}
        sx={{
            pl: 2,
            pr: 2,
            textTransform: "none",
            borderRadius: '10px',
            backgroundColor: bgColor,
            color: color,
            ":hover": {
                backgroundColor: bgColor,
                color: color,
            }
        }}
        endIcon={icon}>
        {loaderEffect
            ? (<CircularProgress size="30px" thickness={2} sx={{ color: nbsColors.white }}/>)
            : (<Typography>{label}</Typography>)
        }
    </Button>);
});

export default CustomButton;