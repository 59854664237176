import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { nbsColors } from '../constants/colors';

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loader: {
        position: 'relative',
        marginBottom: "8px",
    },
    label: {
        color: nbsColors.blue,
    },
}));

const Loader = ({ label = '', size = 40 }) => {
    const classes = useStyles();

    return (
        <div className={classes.loaderContainer}>
            <div className={classes.loader}>
                <CircularProgress
                    variant="indeterminate"
                    sx={{
                        color: nbsColors.blue
                    }}
                    disableShrink
                    size={size}
                    thickness={2}
                    className={classes.circularProgress}
                />
            </div>
            <Typography variant="body1" className={classes.label}>
                {label}
            </Typography>
        </div>
    );
};

export default Loader;
