import {Alert, Stack, Typography} from "@mui/material";
import {nbsColors} from "../constants/colors";
import * as React from "react";
import PropTypes from "prop-types";

const EligibilityError = ({errorMessage}) => {
    return (<Stack sx={{ width: '100%' }} spacing={2}>
        <Alert icon={false} severity="error" sx={{color: nbsColors.red }}>
            <Typography fontWeight={600}>{errorMessage}</Typography>
        </Alert>
    </Stack>);
}

EligibilityError.propTypes = {
    errorMessage: PropTypes.string.isRequired
}

export default EligibilityError;