import axios from "axios";
import {dateStringToYYYYMMDDFormat} from "../utils/DateUtils";
import {channel} from "../constants/messages";

class CommonsAPI {
    #errorResponse = {
        status: false,
        msg: "Something went wrong"
    }
    async fetchBranches() {
        return await axios.get("/api/branches")
            .then((res) => res.data)
            .catch((err) => {
                console.error(err);
                return [];
            })
    }

    async validateAccount(nationalId, accountNumber) {
        if (!nationalId || !accountNumber) {
            return this.#errorResponse;
        }

        const data = {
            "source": channel,
            "account_number": accountNumber,
            "first_name": nationalId?.firstName,
            "surname": nationalId?.surname,
            "other_name": nationalId?.otherName,
            "id_number": nationalId?.idNumber,
            "gender": nationalId?.gender,
            "issue_date": dateStringToYYYYMMDDFormat(nationalId?.issueDate),
            "date_of_birth": dateStringToYYYYMMDDFormat(nationalId?.dateOfBirth),
            "expiry_date":dateStringToYYYYMMDDFormat(nationalId?.expiryDate)
        }

        return await axios.post("/api/validate-account", data)
            .then((res) => res.data)
            .catch((err) => {
                console.error(err);
                return this.#errorResponse;
            })
    }

    async verifyOtp (accountNumber, otp) {
        return await axios.post("/api/verify-otp", {
            account_number: accountNumber,
            otp: otp
        })
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            })
    }

    async fetchLoans(accountNumber) {
        return await axios.post("/api/loan-debts", {account_number: accountNumber})
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async eligibility(accountNumber, fixedAccount, loanId) {
        const data = {
            account_number: accountNumber,
            fixed_account: fixedAccount ?? "",
            loan_id: loanId
        }

        return await axios.post("/api/eligibility", data)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async charges(accountNumber, loanId, amount) {
        const data = {
            account_number: accountNumber,
            loan_id: loanId,
            amount: amount
        }
        return await axios.post("/api/charges", data)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async interest(accountNumber, loanId) {

        return await axios.post("/api/interest", {account_number: accountNumber, loan_id: loanId})
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async monthlyInstallments(accountNumber, term, loanId, amount) {
        const data = {
            "account_number": accountNumber,
            "term": term,
            "loan_id": loanId,
            "amount": amount
        }
        return await axios.post("/api/amortization", data)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async apply(loanApplication) {
        return await axios.post("/api/apply", loanApplication)
            .then((res) => {
                return res.data
            })
            .catch((err) => {
                console.error(err)
            });
    }

    async fetchLoanTypes() {
        return await axios.get("/api/loan-types")
            .then((res) => res.data)
            .catch((err) => {
                console.error(err);
                return [];
            })
    }

    async isBackendServerReachable () {
        return await axios.get(`/api/health-check`)
            .then((r) => r.data.connected)
            .catch(() => false)
    }

    async fetchFixedAccounts(accountNumber) {
        const body = {
            "account_number": accountNumber
        }
        return await axios.post("/api/fixed-accounts", body)
            .then((res) => res.data)
            .catch((err) => {
                console.error(err);
                return [];
            })
    }

    async getAppVersion() {
        return await axios.get(`/api/version`)
            .then((res) => {
                if ("version" in res.data) {
                    return res.data.version
                }
                return null;
            }).catch((error) => {
                console.error(error)
                return null
            })
    }

    async isTokenValid() {
        return await axios.get(`/api/token-validity-check`)
            .then((r) => r.data.valid)
            .catch(() => false)
    }
}

export default CommonsAPI;