import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Roboto, sans-serif',
        color: '#262626',
        marginBottom: "32px",
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: 10,
        marginTop: "32px",
        textAlign: 'center',
    },
    section: {
        textAlign: 'left',
        marginBottom: "16px",
    },
    paragraph: {
        marginBottom: "16px",
    },
}));

const AvailabilityOfFunds = `The Bank shall not be liable for any failure to perform its obligations hereunder 
    resulting directly or indirectly from the action or inaction of any government or local authority or any strike, boycott, 
    blockade act of god, civil disturbance or for any other act which is beyond the control of the Bank.
`;

const RemediesAndWaivers = `
    The Banks' rights under this Facility Letter are cumulative, may be exercised as often as the Bank considers 
    appropriate and are in addition to its rights under the general law. The Banks' rights 
    (whether arising under this Facility Letter or under the general law) will not be capable of being waived or varied, 
    otherwise than by an express waiver or variation in writing. Any failure by the Bank to exercise, or any delay 
    in exercising, any of such rights will not operate as a waiver or variation of that or any other such right; any 
    defective or partial exercise of any such right will not preclude any other or further exercise of that or any other 
    such rights; and no act or course of conduct or negotiation on the part of the Bank shall preclude the Bank from 
    exercising any such right or constitute a suspension or any variation of such right.
`;

const Payments = `
    All payments by the Borrower under the Facilities, whether of principal, interest, fees, costs or otherwise, 
    shall be made in full in immediately available funds, without set-off or counterclaim and free and clear of any 
    deduction or withholding on account of tax or otherwise. If the Borrower is required by law to make any deduction or 
    withholding from any payment under the Facilities, the sum due from the Borrower in respect of such payment shall be 
    increased to the extent necessary to ensure that, after the making of such deduction or withholding, the Bank 
    receives a net sum equal to the sum which it would have received had no such deduction or withholding been required.
`;

const Certificate = `
    A certificate signed by any manager of the Bank (whose appointment or authority need not be proved) as to any 
    amount owing to the Bank under the Facility Letter, by the Borrower, the rates of interest and any other fact stated 
    therein, shall, on its mere production, be prima facie proof of the content of such certificate.
`;

const Notices = `
    Any notice or communication requiring to be served on the Borrower may be served on it or on any of its officers 
    personally by hand delivery, by telex/telefacsimile or by post. Service by hand delivery shall be effective on actual 
    time of delivery. Service by telex/telefacsimile shall be to the Borrower's last known fax number and shall be deemed 
    to have been served on the first business day following transmission. Service by post shall be to the postal address
    on the last page of this Facility Letter or to the Borrower's registered address or at some address as notified 
    from time to time by the Borrower and shall be deemed to have been effected five business days after posting. 
    Notwithstanding anything to the contrary in this paragraph a written notice or other communication actually
    received by any party shall be adequate written notice or communication to it notwithstanding that the notice 
    was not sent to or delivered at its chosen address.
`;

const Severability = `
    Each provision of the Facility Letter is severable, the one from the other and, if at any tim any provision 
    is or becomes or is found to be illegal, invalid, defective or u enforceable for any reason by any competent court, 
    the remaining provisions shall be of full force and effect and shall continue to be of full force and effect. 
`;

const NBSBankTerms = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root}>
            <Typography mb={1} variant="h4" className={classes.title}>Terms & Conditions</Typography>
            <Divider />
            <Box mt={1} className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Availability of Funds</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {AvailabilityOfFunds}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Remedies and Waivers</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {RemediesAndWaivers}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Payments</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {Payments}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Certificate</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {Certificate}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Notices</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {Notices}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Severability</Typography>
                <Typography paragraph className={classes.paragraph}>
                    {Severability}
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Representations and Warranties</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Borrower represents and warrants to the Bank that:- <br/>
                    - They have legal capacity to enter this agreement <br/>
                    - This Facility Letter terms and conditions, when executed, will constitute legal, valid and binding
                    obligations or those of the provider thereof; <br/>
                    - He/She has the power to enter into and perform in terms of the Facility Letter, and all necessary
                    shareholder and corporate consents have been obtained for the acceptance of the Facilities, the
                    grant of the security and the execution and delivery of this Facility Letter and the Security; <br/>
                    - The acceptance of the Facilities do not contravene any agreement or instrument to which it or the
                    provider thereof is a party; <br/>
                    - He/She is not bankrupt or have any steps been taken or are, to the best of him/her knowledge,
                    threatened against him/her for bankruptcy; no action or litigation is pending or, to the best of its
                    knowledge, threatened against him/her. <br/>
                    - There are no material facts or circumstances in respect of the Borrower's, affairs, business and
                    operations which have not been fully disclosed which would be likely to adversely effect the
                    decision of the Bank to advance the Facilities. <br/>
                    - Any factual information provided by the Borrower is true and accurate in all material
                    aspects. <br/>
                    - Nothing has occurred or has been omitted from the information provided to the Lender by any member
                    of the Borrower and no information has been given or withheld that results in the information
                    contained in any such information being untrue or misleading in any material respect. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Confidential Information</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Borrower agrees with the Bank that the Bank may:- <br/>
                    - Hold and process, by computer or otherwise, any information obtained about the Borrower as a
                    consequence of the Facilities contemplated in this Facility Letter; <br/>
                    - Include personal data in the Bank's systems which may be accessed by other companies in the Bank's
                    group for credit assessment, statistical analysis including behaviour and scoring and to identify
                    products and services (including those supplied by third parties) which may be relevant to the
                    Borrower; and permit other companies within the Bank's group to use personal data and any other
                    information it holds about the Borrower to bring to its attention products and services which may be
                    of interest to the Borrower. <br/>
                </Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Borrower acknowledges and agrees that, notwithstanding the terms of any other agreement between
                    the Borrower and the Bank, a disclosure of information by the Bank in the circumstances contemplated
                    by this clause does not violate any duty owed to the Borrower either in common law or pursuant to
                    any agreement between the Bank and the Borrower or in the ordinary course of banking business and
                    the customs, usage and practice related thereto and further that disclosure as aforesaid may be made
                    without reference to or further authority from the Borrower and without inquiry by the Bank as to
                    the justification for or validity of such disclosure. <br/>
                </Typography>

                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Force Majeure</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Lender shall not be held responsible for any loss or damage or failure to perform all or any of
                    its obligations herein resulting from any legal enactment, intervention of a public authority, act
                    of war, strike, lockout, boycott, blockade or any other similar circumstance. The reservation in
                    respect of strikes, lockouts, boycotts and blockades shall apply even if the Lender itself takes
                    such measures or is subject of such measures. <br/>
                    This facility letter supersedes any letters or correspondence that may have been exchanged between
                    the Lender and the Borrower in respect of this facility and when duly signed by the Lender's
                    signatories is an offer of the facilities detailed herein, which are available for acceptance by the
                    Borrower within a period of 14 (fourteen) days from the date of this letter, after which date the
                    Lender will assume that the offer has been declined. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Set Off</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Bank may, at any time without notice or demand to the Borrower and notwithstanding any
                    settlement of account or other matter whatsoever, combine or consolidate all or any then existing
                    accounts of the Borrower with the Bank including accounts in the name of the Bank whether current,
                    deposit, loan or of any other nature whatsoever, whether subject to notice or not and in whatever
                    currency denominated and whether held in the name of the Borrower alone or jointly with others
                    wherever situate and set off or transfer any sums standing to the credit of any one or more such
                    accounts in or towards satisfaction of any obligations and liabilities to the Bank of the Borrower
                    whether such liabilities be present, future, actual, contingent, primary, collateral, joint or
                    several and the Borrower expressly waives any rights of set-off that the Borrower may have, so far
                    as is permitted by law, in respect of any claim which it may now or at any time hereafter have
                    against the Bank and the Bank may use any such money to purchase any currency or currencies required
                    to effect such application. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Assignment</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Borrower hall not be entitled to assign all or any part of its obligations or benefits under
                    this Facility Letter without the prior written consent of the Bank. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Governing Law and Jurisdiction</Typography>
                <Typography paragraph className={classes.paragraph}>
                    This Facility Letter shall be governed and construed in accordance with Malawi law and the courts of
                    Mal wi shall have jurisdiction to settle any disputes which may arise in connection therewith
                    without prejudice to the exclusive right of the Bank to institute proceedings against the Borrower
                    in respect thereof in any other jurisdiction. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Compliance with laws and sanctions</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The Borrower shall comply in all respects with all laws to which it may be subject, if failur so to
                    comply would materially impair its ability to perform its obligations u der the Facility
                    Letter. <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>General Undertakings</Typography>
                <Typography paragraph className={classes.paragraph}>
                    While the Facilities remain available or any amount or commitment remains outstanding, the Borrower
                    undertakes to the Bank:
                    - He/she shall not become surety, guarantor for or give indemnity on behalf of any third party or
                    render itself liable in any way whatsoever for the debts or engagements of any other party without
                    the prior written consent of the Bank; <br/>
                    - that he/she shall, immediately upon becoming aware of it, notify the Bank of any material
                    litigation, arbitration or administrative proceeding pending or, to the best of its knowledge,
                    information and belief, threatened against the Borrower.

                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Whole Agreement, Variation of Terms, No Indulgence</Typography>
                <Typography paragraph className={classes.paragraph}>
                    The agreement created upon acceptance of the Facility Letter by the Borrower shall constitute the
                    whole agreement between the Bank and the Borrower relating to the subject matter of the Facility
                    Letter. No addition to, variation, or amendment, or consensual cancellation of any of the terms
                    contained in the Facility Letter shall be of any force or effect unless it is recorded in writing
                    and is signed on behalf of the Bank by one of its authorised officials and accepted by the Borrower.
                    No indulgence shown or extension of time given by the Bank shall operate as an estoppel against the
                    Bank or waiver of any of the Bank's rights unless recorded in writing and signed by the Bank. The
                    Bank shall not be bound by any express or implied term, representation, warranty, promise or the
                    like not recorded herein, whether it induced the conclusion of any agreement and/or whether it was
                    negligent or not <br/>
                </Typography>
                <Divider />
            </Box>
            <Box className={classes.section}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Complaints Handling</Typography>
                <Typography paragraph className={classes.paragraph}>
                    Where a Borrower has a complaint in respect of a facility, the Bank shall ensure that it is
                    professionally and urgently handled, and that the Borrower is kept informed of the progress in the
                    complaint resolution process. <br/>

                    Where the Borrower has such complaint, he shall lodge the complaint in the following ways; Write a
                    letter to: <br/><br/>

                    NBS Bank Plc <br/>
                    P.O Box 32251 <br/>
                    Chichiri <br/>
                    Blantyre 3 <br/>
                    Call: 322/01876222 <br/>
                    Email:nbs@nbs.mw <br/>
                </Typography>
            </Box>
        </Container>
    );
};

export default NBSBankTerms;
