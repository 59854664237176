import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import { nbsColors } from "../constants/colors";

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: nbsColors.lightGrey,
                textAlign: 'center',
                position: 'fixed',
                bottom: 0,
                width: '100%',
            }}>
            <Container maxWidth="lg">
                <Typography variant="body2" color={nbsColors.red} fontWeight={600}>
                    &copy; {new Date().getFullYear()} NBS Bank Plc. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
