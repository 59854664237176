import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {AccountInformationPage} from "./pages/AccountInformationPage";
import LoansPage from "./pages/LoansPage";
import EligibilityPage from "./pages/EligibilityPage";
import {ChargesPage} from "./pages/ChargesPage";
import FinishPage from "./pages/FinishPage";
import OtpPage from "./pages/OtpPage";
import HomePage from "./pages/HomePage";
import MaintenancePage from "./pages/MaintenancePage";
import webRoutes from "./constants/routes";
import FixedAccountsPage from "./pages/FixedAccountsPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";

function App() {
  return (
      <Router>
        <Routes>
            <Route path={webRoutes.home} element={<HomePage/>}></Route>
            <Route path={webRoutes.customer} element={<AccountInformationPage/>}></Route>
            <Route path={webRoutes.otp} element={<OtpPage/>}></Route>
            <Route path={webRoutes.loans} element={<LoansPage/>}></Route>
            <Route path={webRoutes.eligibility} element={<EligibilityPage/>}></Route>
            <Route path={webRoutes.charges} element={<ChargesPage/>}></Route>
            <Route path={webRoutes.finish} element={<FinishPage/>}></Route>
            <Route path={webRoutes.maintenance} element={<MaintenancePage/>}></Route>
            <Route path={webRoutes.fixedAccounts} element={<FixedAccountsPage/>}></Route>
            <Route path={webRoutes.unauthorized} element={<UnauthorizedPage/>}></Route>
        </Routes>
      </Router>
  );
}

export default App;
