import { Box, Checkbox, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import * as React from "react";
import {nbsColors} from "../constants/colors";
import {useNavigate} from "react-router-dom";
import LoanApplication from "../structs/LoanApplication";
import NextButton from "./NextButton";
import BackButton from "./BackButton";

const LoanEligibilityComponent = ({ eligibilityResponse, loanApplication}) => {
    const [eligibility, setEligibility] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if ('amount' in eligibilityResponse) {
            const application = LoanApplication.fromJSON(loanApplication);
            application.term = 1;
            application.amount = eligibilityResponse['amount']

            navigate("/charges", {state: application});
        } else {
            delete eligibilityResponse["status"]
            setEligibility(eligibilityResponse);
        }
    }, []);

    const navigator = useNavigate();
    const [checked, setChecked] = React.useState(null);
    const handleToggle = (value) => () => setChecked(value);

    const goToNextPage = () => {
        const selectedLoanTerm = Object.entries(eligibilityResponse)
            .find((keyValue, index) => index === checked)

        const application = LoanApplication.fromJSON(loanApplication);
        application.term = selectedLoanTerm[0];
        application.amount = selectedLoanTerm[1]

        navigator("/charges", {state: application});
    }

    return (<>
        <ListItem
            secondaryAction={<Typography fontWeight={600} color={nbsColors.blue}>
                Amount (MWK)
            </Typography>}
            disablePadding>
            <ListItemText primary={<Typography fontWeight={600} color={nbsColors.blue}>
                Months
            </Typography>} />
        </ListItem>

        <Box mb={2} p={1} borderRadius="10px" sx={{ border: `1px solid ${nbsColors.lightGrey}` }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {Object.keys(eligibilityResponse).map((key, index) => {
                    const amount = eligibilityResponse[key];
                    const labelId = `checkbox-list-label-${amount}`;

                    return (
                        <ListItem
                            divider={index < Object.keys(eligibilityResponse).length - 1}
                            sx={{
                                backgroundColor: index === checked ? nbsColors.lightGrey : null,
                            }}
                            key={index}
                            secondaryAction={<Typography color={nbsColors.blue}>{amount.toLocaleString()}</Typography>}
                            disablePadding>
                            <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked === index}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={<>
                                    <Typography color={nbsColors.blue}>
                                        {key}
                                    </Typography>
                                </>} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>

        <Box mt={4} display="flex" justifyContent="space-between">
            <BackButton/>
            <NextButton disabled={checked === null} onNextCallback={goToNextPage}/>
        </Box>
    </>);
}

export default LoanEligibilityComponent;