export const nbsColors = {
    red: "#ed1b2e",
    blue: "#2b357c",
    grey:  "#C0C0C0",
    lightGrey: "#ededed",
    white: "#ffffff",
    errorRed: "#DC143C",
    green: "#4bb71b",
    black: "#000000"
}

