const webRoutes = {
    home: "/",
    customer: "/customer",
    otp: "/otp",
    loans: "/loans",
    eligibility: "/eligibility",
    charges: "/charges",
    finish: "/finish",
    maintenance: "/maintenance",
    fixedAccounts: "/fixed-accounts",
    unauthorized: "/unauthorized"
}

export default webRoutes