import {
    Box, Card, CardContent,
    Container, Typography,
} from "@mui/material";
import {Header} from "../components/Header";
import * as React from "react";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import LoanEligibilityComponent from "../components/LoanEligibilityComponent";
import ConnectivityChecker from "../components/ConnectivityChecker";
import {nbsColors} from "../constants/colors";

const EligibilityPage = () => {
    const [eligibilityResponse, setEligibilityResponse] = useState(null);
    const [loanApplication, setLoanApplication] = useState(null);
    const location = useLocation();

    React.useEffect(() => {
        if (location.state !== null) {
            const elResponse = location.state.eligibility;
            const application = location.state.application;

            setEligibilityResponse(elResponse);
            setLoanApplication(application)
        }
    }, []);

    return (<>
        <Header/>
        <ConnectivityChecker/>
        <Container maxWidth="md" sx={{ marginTop: "1rem" }}>
            <Box sx={{ borderRadius: "100px", marginTop: "0.5rem"}}>
                <Card elevation={0} sx={{ borderRadius: "10px" }}>
                    <CardContent>
                        {loanApplication !== null && (
                            <Box pl={2} display="flex" justifyContent="flex-start">
                                <Typography
                                    sx={{
                                        color: nbsColors.blue,
                                        fontWeight: "600"
                                    }}>
                                    {`${loanApplication.loan_type} eligibility`}
                                </Typography>
                            </Box>
                        )}

                        <Box p={2}>
                            {eligibilityResponse && loanApplication && (<>
                                <LoanEligibilityComponent loanApplication={loanApplication} eligibilityResponse={eligibilityResponse}/>
                            </>)}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    </>);
}

export default EligibilityPage;