import CommonsAPI from "../api/CommonsAPI";
import { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import webRoutes from "../constants/routes";

const ConnectivityChecker = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isConnectionOk = async () => await new CommonsAPI().isBackendServerReachable();

    const currentPageIsMaintenancePage = () => {
        return location.pathname.endsWith(webRoutes.maintenance);
    }

    useEffect(() => {
        isConnectionOk()
            .then((ok) => {
                if (!ok) {
                    navigate(webRoutes.maintenance, { replace: true });
                } else if (currentPageIsMaintenancePage()) {
                    navigate(webRoutes.home, { replace: true });
                }
            })
            .catch(() => false);
    }, []);

    return (<></>);
}

export default ConnectivityChecker;