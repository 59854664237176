import {
    AppBar,
    Box, Card, CardContent,
    Container, Toolbar,
    Typography
} from "@mui/material";
import * as React from "react";
import {useLocation, useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import {nbsColors} from "../constants/colors";
import {ResponseFailureAnimation, ResponseSuccessAnimation} from "../components/ResponseAnimations";
import DialogActions from "@mui/material/DialogActions";
import CustomButton from "../components/CustomButton";
import {useEffect, useState} from "react";
import Loader from "../components/Loader";
import logoImage from "../assets/images/logo_nav.png";
import {Header} from "../components/Header";
import BackGroundPaper from "../components/BackGroundPaper";

const AppBarHeader = React.memo(() => {
    const navigate = useNavigate();
    const handleFinish = () => navigate('/');

    return (<Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ bgcolor: nbsColors.red }}>
            <Toolbar>
                <div style={{ cursor: "pointer" }} onClick={handleFinish}>
                    <img src={logoImage} alt="NBS Bank Logo" width={30} height={30} />
                </div>
                <div style={{ cursor: "pointer" }} onClick={handleFinish}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            pl: "5px"
                        }}
                    >
                        Instant Loans
                    </Typography>
                </div>
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </AppBar>
    </Box>)
});

const FinishPage = () => {
    const [response, setResponse] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleFinish = () => navigate('/');

    useEffect(() => {
        const disableBack = (e) => {
            e.preventDefault();
            window.history.forward();
        };

        if (location.state) {
            setResponse(location.state);
        }

        // Disable back navigation
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', disableBack);

        return () => {
            window.removeEventListener('popstate', disableBack);
        };
    }, [])

    return (
        <BackGroundPaper>
            <>
                <Header/>
                <Container maxWidth="md" sx={{ marginTop: "1rem" }}>
                    <div style={{
                        borderRadius: "8px",
                        textAlign: 'center',
                    }}>
                        {response
                            ? (<>
                                <Card elevation={0}>
                                    <CardContent>
                                        <DialogTitle sx={{ color: nbsColors.blue, fontWeight: 600 }}>
                                            {`Application ${response?.status ? 'completed' : 'failed'}`}
                                        </DialogTitle>

                                        {response?.status
                                            ? (<ResponseSuccessAnimation/>)
                                            : (<ResponseFailureAnimation/>)}

                                        <Box mt={2} p={1}>
                                            <Typography color={nbsColors.blue}>{response?.message}</Typography>
                                        </Box>

                                        <DialogActions style={{ justifyContent: 'center' }}>
                                            <CustomButton label="Finish" onClickCallback={handleFinish}/>
                                        </DialogActions>
                                    </CardContent>
                                </Card>
                            </>)
                            : (<Box>
                                <Loader/>
                            </Box>)
                        }
                    </div>
                </Container>
            </>
        </BackGroundPaper>
    );
}

export default FinishPage;